import {inEditor} from './inEditor';

window.plausible =
  window.plausible ||
  function() {
    (window.plausible.q = window.plausible.q || []).push(arguments)
  };

function handleClick(event) {
  const middle = event.type === 'auxclick' && event.which === 2;
  const click = event.type === 'click';

  let link = event.target;

  while (link &&
         (!link.getAttribute || !link.getAttribute('data-event')) &&
         (typeof link.tagName == 'undefined' ||
          link.tagName.toLowerCase() !== 'a' ||
          !link.href)) {
    link = link.parentNode;
  }

  if (link &&
      (link.getAttribute('data-event') ||
       (link.href && link.host && link.host !== window.location.host))) {
    if (middle || click) {
      const props = link.getAttribute('data-event-props') ?
                    JSON.parse(link.getAttribute('data-event-props')) :
                    {};

      if (link.href) {
        props.href = link.href;
      }

      window.plausible(
        link.getAttribute('data-event') || 'Outbound Link: Click',
        {props}
      );
    }

    if (link.href &&
        link.href.includes('//creator.hosted-pageflow.com/registrations/new') &&
        !link.href.includes('utm_campaign=')) {
      const utmQueryString = getUtmQueryString();

      if (utmQueryString) {
        const separator = link.href.includes('?') ? '&' : '?';
        link.href = link.href + separator + utmQueryString;
      }
    }

    // Delay navigation so that Plausible is notified of the click
    if (link.href && (!link.target || link.target.match(/^_(self|parent|top)$/i))) {
      if (!(event.ctrlKey || event.metaKey || event.shiftKey) && click) {
        setTimeout(function() {
          window.location.href = link.href;
        }, 150);
        event.preventDefault();
      }
    }
  }
}

if (!inEditor()) {
  document.addEventListener('click', handleClick)
  document.addEventListener('auxclick', handleClick)
}

function getUtmQueryString() {
  try {
    return window.localStorage['pageflow.utm'] || '';
  }
  catch(e) {
    return '';
  }
}
